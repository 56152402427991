// Paths
export const HOME_PATH = "/";
export const UNITS_LIST_PATH = "/units";
export const STUDY_OPTIONS_PATH = "/study-options-page";
export const VOCAB_OPTIONS_PATH = "/vocab-options-page";
export const GRAMMAR_OPTIONS_PATH = "/grammar-options-page";
export const LISTEN_OPTIONS_PATH = "/listen-options-page";
export const VOCAB_PATH = "/vocab-page";
export const RED_LIGHT_GREEN_LIGHT_PATH = "/red-light-green-light-page";
export const TUG_OF_WAR_PATH = "/tug-of-war-page";
export const SUGAR_HONEYCOMBS = "/sugar-honeycombs";
export const MARBLES = "/marbles";
export const CHATBOX_PATH = "/chatbox-page";
export const FLASHCARD_STUDY_PATH = "/flashcard-study-page";
export const ABOUT_PATH = "/about";
export const PROGRESS_PATH = "/progress-page";
export const LOGIN_PATH = "/login-page";
export const REGISTER_PATH = "/register-page";
export const LOGOUT_PATH = "/logout-page";
export const FORGOT_PASSWORD_PATH = "/forgot-password-page";
export const FEEDBACK_PATH = "/feedback-page";
