

const LISTENING = { 
    1: {
        'question1': ['이름이 아니에요.', '아니에요.', '일학년이에요.', '이한성이에요.'],
        'question2': ['일학년이에요.', '아, 아니에요. 고맙습니다.', '이름이에요.', '이한성이에요.'],
        'question3': ['이한성이에요.', '네, 이학년이에요.', '컴퓨터예요.', '네, 일학년이에요.'],
        'question4': ['네, 안녕하세요.', '컴퓨터 사이언스예요.', '일학년이에요.', '김미나예요.'],
        "answers": ["이한성이에요.", "아, 아니에요. 고맙습니다.", "네, 일학년이에요.", "컴퓨터 사이언스예요."]
    },
    
    2: {
        'question1': ['케네디 기숙사에 살아요.', '이한성이에요.', '아니에요. 고맙습니다.', '아니요. 살아요.'],
        'question2': ['아니요. 약속이 없어요.', '아니요. 오늘은 다른 약속이 있어요.', '네. 저녁이 있어요.', '네. 시간이에요.'],
        'question3': ['네, 여기예요.', '책 읽어요.', '아, 테니스예요.', '어, 뭐 해요?'],
        'question4': ['아니요. 테니스가 아니에요.', '네, 좀 쳐요.', '아니요. 잘 있어요.', '네, 테니스예요.'],
        "answers": ["케네디 기숙사에 살아요.", "아니요. 오늘은 다른 약속이 있어요.", "책 읽어요.", "네, 좀 쳐요."]
    },

    3: {
        'question1': ['아니요. 저녁이 아니에요.', '네, 약속이에요.', '아니요. 없어요. 왜요?', '네, 저녁이 있어요.'],
        'question2': ['네, 어디에 갈까요.', '네, 학교 식당이에요.', '학교 식당은 어때요?', '네, 어디예요.'],
        'question3': ['안녕하세요? 저는 김미나예요.', '네, 그럼 안녕히 가세요.', '아니요, 데이트가 아니에요.', '아니요, 인사가 아니에요.'],
        'question4': ['식당이야.', '식당에 가.', '어, 미나 씨야.', '네, 어디예요.'],
        "answers": ["아니요. 없어요. 왜요?", "학교 식당은 어때요?", "안녕하세요? 저는 김미나예요.", "식당에 가."]
    }, 

    4: {
        'question1': ['아니요. 마트가 안 가요.', '아니요. 저하고만 갈래요.', '네, 같이 마트예요.', '네, 좋아요. 같이 가요.'],
        'question2': ['그래, 좋아, 좋아!', '네, 전화예요.', '네, 마트예요!', '아니요. 라면이 없어요.'],
        'question3': ['맞아요. 어떻게 알아요?', '네, 장유진이 있어요.', '맞아요. 걸스밴드가 있어요.', '네, 걸스밴드가 없어요.'],
        'question4': ['네, 커피 살까요.', '좋아요. 커피라도 살까요.', '네, 기숙사에 살아요.', '좋아요. 그럼 커피 마시러 갈까요?'],
        "answers": ["네, 좋아요. 같이 가요.", "그래, 좋아, 좋아!", "맞아요. 어떻게 알아요?", "좋아요. 그럼 커피 마시러 갈까요?"]
    }, 

    5: {
        'question1': ['친구 만나요!', '네, 맛있어요!', '저는 망고 주스 마실게요!', '집에 가요!'],
        'question2': ['정말? 잘 사귀어 봐!', '그래? 나도 살고 싶어.', '응, 좋아. 같이 가!', '아니. 잘 사귀었어.'],
        'question3': ['고마워요. 그런데 배가 불러요.', '네, 좋아요. 제가 살래요. 망고 주스 먹을래요?', '네, 소미 씨가 살게요.', '네, 뭐 먹을래요.'],
        'question4': ['그래요. 커피 마시러 가요.', '네, 좋아요. 뭐 마실래요?', '네, 눈꽃 빙수 주세요.', '네, 만 원이에요. 감사합니다.'],
        'answers':['저는 망고 주스 마실게요!','정말? 잘 사귀어 봐!','고마워요. 그런데 배가 불러요.','네, 만 원이에요. 감사합니다.']
    }, 

    6: {
        'question1': ['좋지요. 어디에 주말이 가요?', '네, 주말이 가요!', '좋지요. 어디 갈까요?', '네, 어디에 주말이 있어요?'],
        'question2': ['바이킹 탈래요.', '물론이에요.', '집이 어디지요?', '저는 김치요.'],
        'question3': ['네, 토요일에 뭐 할까요!', '나는 롤러코스터 타러 가고 싶어.', '그럼요. 토요일이지요!', '물론이에요. 괜찮지요!'],
        'question4': ['다 정했잖아.', '아니, 바이킹 탔어.', '좋아. 파트너가 아니잖아.', '응, 파트너가 정했어.'],
        "answers": ["좋지요. 어디 갈까요?", "물론이에요.", "나는 롤러코스터 타러 가고 싶어.", "다 정했잖아."]
    } 
};

export function getQuestionAnswer(unitNumber) { 
    console.log("Returning listening hw question set for unit:", unitNumber, LISTENING[unitNumber]);
    return LISTENING[unitNumber]; 

}; 


